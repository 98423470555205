import { inject, Injectable } from '@angular/core';
import { Auth, authState, sendPasswordResetEmail } from '@angular/fire/auth';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';

import { PathConstants } from '../core/config/db.paths';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private afAuth = inject(Auth);
  private authState$ = authState(this.afAuth);
  private firestore = inject(Firestore);


  /**
   * This constructor is called when the service is initialized. It sets up a
   * subscription to the auth state of the user. When the user is logged in,
   * it fetches the user's details from Firestore. The user's details are
   * stored in a BehaviorSubject, which is an observable that allows other
   * components to subscribe to it and receive the user's details.
   *
   * @remarks
   * The user's details are stored in a Firestore document with the path
   * /users/$uid. The document contains the user's details, such as name,
   * email, and organization code.
   * The user's details are fetched from Firestore when the user is logged
   * in.
   * The user's details are stored in a BehaviorSubject, which is an
   * observable that allows other components to subscribe to it and receive
   * the user's details.
   */
  constructor() {
    this.authState$
      .pipe(
        switchMap((user) => {
          console.log('[AuthService] authState - user:', user);
          if (user && user.uid) {
            const path = PathConstants.getUserDetailsPath(user.uid);
            console.log('[AuthService] Generated Firestore path:', path);

            const docRef = doc(this.firestore, path);
            return docData<any>(docRef, { idField: 'id' }).pipe(
              map((userData) => {
                if (!userData) {
                  console.log('[AuthService] No data found for the provided UID:', user.uid);
                  return null;
                }
                return {
                  ...userData,
                  metadata: user.metadata,
                };
              })
            );
          } else {
            console.log('[AuthService] User or UID is missing. Skipping path creation.');
            return of(null);
          }
        })
      )
      .subscribe((user) => {
        if (user && user.superAdmin ) {          
          this.userSubject.next(user);
        } else {
          this.userSubject.next(null);
        }
      });
  }


  getLoggedInUser(): Observable<any> {
    return this.userSubject.asObservable();
  }

  isValidLogin(): Observable<boolean> {
    return this.getLoggedInUser().pipe(map((user) => !!user));
  }

  login(email: string, password: string) {
    return signInWithEmailAndPassword(this.afAuth, email, password);
  }

  logout() {
    return this.afAuth.signOut().then(() => {
      this.userSubject.next(null);
    });
  }

  resetPassword(email: string) {
    return sendPasswordResetEmail(this.afAuth, email);
  }
}
